<template>
  <!--
    Displays devices, that are missing essential configurations and makes them editable.
  -->
  <div class="unconfiguredDeviceDisplay">
    <LoadingPlaceholder
      v-if="loading"
      class="mt-4"
    />
      
    <template v-else>
      <template v-if="devicesAndInstallationsReady">
        <table
          ref="fml"
          class="defaultTable"
        >
          <colgroup>
            <col width="50px">
          </colgroup>

          <thead>
            <tr>
              <th>&nbsp;</th>
              <th>{{ $t('name') }}</th>
              <th class="only-screen-xl">
                {{ $t('deviceType') }}
              </th>
              <th class="only-screen-xl">
                {{ $t('deviceDetailTableComponent.uuid') }}
              </th>
              <th class="only-screen-xl">
                {{ $t('applicationType') }}
              </th>
              <th class="only-screen-xxl">
                {{ $t('installedVersion') }}
              </th>
              <th class="only-screen-lg">
                {{ $t('vpnIpAddress') }}
              </th>
              <th class="only-screen-md">
                {{ $t('tags') }}
              </th>
            </tr>
          </thead>

          <tbody>
            <tr
              v-for="(device, index) in unconfiguredDevices"
              :key="`UnconfiguredDevicesDisplay-device-table-row-${ index }`"
              :class="[{ 'clicked': clickedItem == index }]"
            >
              <td class="text-center">
                <div class="form-check">
                  <input
                    v-model="device.setDevice"
                    type="checkbox"
                    class="form-check-input alt-pointer"
                  >
                </div>
              </td>
              <td>
                <router-link
                  :to="`/centraldevicemanagement/unconfigured-view/${ device.uuid }/null`"
                  target="_blank"
                >
                  {{ device.name }}
                </router-link>
              </td>
              <td class="only-screen-xl">
                {{ device.deviceType }}
              </td>
              <td class="only-screen-xl">
                {{ device.uuid }}
              </td>
              <td class="only-screen-xl">
                {{ device.applicationType }}
              </td>
              <td class="only-screen-xxl">
                {{ device.installedVersion }}
              </td>
              <td class="only-screen-lg">
                <div style="display: flex;">
                  <p
                    style="width:110px; margin-bottom: 0px;"
                  >
                    {{ device.vpnIpAddress }}
                  </p>
                  <button
                    class="btn btn-primary btn-sm"
                    @click="getGeoIp(device.uuid); clickedItem = index"
                  >
                    <font-awesome-icon
                      class="mr-1"
                      icon="globe-europe"
                      style="vertical-align: middle;"
                    />
                    Geo Ip
                  </button>
                </div>
              </td>
              <td class="only-screen-md">
                {{ tagsToString(device.tags) }}
              </td>
            </tr>
          </tbody>
        </table>

        <fieldset
          v-if="deviceIsSet"
          class="border p-2 mt-3"
        >
          <legend class="w-auto">
            {{ $t('unconfiguredDevicesDisplayComponent.assignDevices') }}
          </legend>
          <div
            class="alert alert-info"
            role="alert"
          >
            {{ $t('unconfiguredDevicesDisplayComponent.infoText') }} 
          </div>
          <div class="form-group form-row row pl-3 pr-3 pb-2">
            <div class="col-12 col-md-7">
              <multiselect
                ref="unconfiguredDevicesDisplayMultiselect"
                v-model="installationToUse"
                :options="installations"
                :taggable="false"
                :show-labels="true"
                :searchable="true"
                :close-on-select="true"
                :hide-selected="false"
                :multiple="false"
                :deselect-label="$t('unconfiguredDevicesDisplayComponent.clickToDeselect')"
                :select-label="$t('unconfiguredDevicesDisplayComponent.clickToSelect')"
                :placeholder="$t('unconfiguredDevicesDisplayComponent.chooseAnInstallation')"
                class="m-input m-input--air alt-pointer"
                label="name"
                track-by="id"
              >
                <template
                  slot="option"
                  slot-scope="{option}"
                >
                  <span>{{ option.name }}</span>
                </template>
                <template
                  slot="tag"
                  slot-scope="{option}"
                >
                  <span
                    class="m-badge m-badge--primary m-badge--wide"
                    @click="unsetMultiselect()"
                  >{{ option.name }} &times;</span>
                </template>
              </multiselect>
              <br class="d-inline-block d-md-none">
            </div>
            <div class="col-12 col-md-5">
              <button
                :disabled="!readyToSetDevice"
                class="btn btn-primary"
                @click="setDeviceInstallationId()"
              >
                {{ $t('assign') }} ({{ devicesToSet.length }} {{ $t('devices') }})
              </button>
            </div>
          </div>
        </fieldset>
      </template>

      <p v-else>
        {{ $t('noDataAvailable') }}
      </p>
    </template>
    <SweetModal
      ref="geoMap"
      blocking
      class="overflowHidden"
    >
      <template slot="box-action">
        <toggle-button
          v-model="baseLayerBool"
          :labels="{ checked: $t('map'), unchecked: $t('satellite') }"
          :color="{ checked: 'gray', unchecked: 'green' }"
          :width="80"
          :height="30"
          :font-size="10"
          class="m-0"
          @change="changeBaseLayer()"
        />
      </template>
      <LoadingPlaceholder v-if="geoMapLoading" />
      <div v-else>
        <div
          v-if="deviceLocation"
          style="display:flex; justify-content: center;"
        >
          <h5
            v-if="deviceLocation.city"
            class="mr-4"
          >
            City - {{ deviceLocation.city }}
          </h5>
          <h5 v-if="deviceLocation.country_name">
            Country - {{ deviceLocation.country_name }}
          </h5>
        </div>
        <div
          id="map"
          style="width: 100%; height: 100%; min-height: 400px;"
        />
      </div>
    </SweetModal>
  </div>
</template>

<script>
import { SweetModal } from 'sweet-modal-vue';
import * as L from 'leaflet';

export default {
  name: 'UnconfiguredDeviceDisplay',
  components: {
    Multiselect: () => import('vue-multiselect'),
    SweetModal
  },
  data () {
    return {
      loading: false,
      searchtext: "",
      installations: null,
      devices: null,
      cancelSource: null,
      installationToUse: null,
      geoMapLoading: false,
      zoomIn: false,
      map: null,
      mapAttrib: '&copy; <a href="http://www.esri.com/">Esri</a>',
      mapUrl: 'http://server.arcgisonline.com/ArcGIS/rest/services/World_Imagery/MapServer/tile/{z}/{y}/{x}',
      wikiAttrib: '&copy; <a href="https://wikimediafoundation.org/wiki/Maps_Terms_of_Use">Wikimedia</a>',
      wikiUrl: 'https://a.tile.openstreetmap.org/{z}/{x}/{y}.png',
      contributionUrl: '&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors',
      baseLayerBool: true,
      baseLayers: null,
      deviceLocation: null,
      clickedItem: null
    }
  },
  metaInfo () {
    return {
      title: this.$t('menu.unconfiguredDevices')
    }
  },
  computed: {
    devicesAndInstallationsReady () {
      if (!this.devices) {
        return false;
      }
      if (!this.devices.length) {
        return false;
      }
      if (this.devices.length == 0) {
        return false;
      }
      if (!this.installations) {
        return false;
      }
      if (!this.installations.length) {
        return false;
      }
      if (this.installations.length == 0) {
        return false;
      }
      return true;
    },
    deviceIsSet: function () {
      return this.devicesToSet.length > 0;
    },
    devicesToSet: function () {
      if (!this.devices) {
        return null;
      }
      return this.devices.filter((device) => {
        return device.setDevice;
      });
    },
    unconfiguredDevices: function () {
      if (!this.devices) {
        return null;
      }
      return this.devices.filter((device) => {
        return !device.installationId && device.vpnIpAddress
      });
    },
    readyToSetDevice: function () {
      if (!this.installationToUse) {
        return false;
      }
      if (this.devicesToSet.length == 0) {
        return false;
      }
      return true;
    }
  },
  created () {
    this.searchDevices();
  },
  beforeDestroy () {
    this.cancelSearch();
  },
  mounted () {
    // this.initMap();
  },
  methods: {
    showGeoMap () {
      this.$refs.geoMap.open();
    },
    closeGeoMap () {
      this.$refs.geoMap.close();
    },
    unsetMultiselect () {
      this.installationToUse = null;
    },
    tagsToString (tags) {
      if (!tags || tags.length == 0) {
        return '';
      }
      let returnString = ``;
      let tagsLength = tags.length;
      tags.forEach((tag, index) => {
        if (index + 1 >= tagsLength) {
          returnString += `${ tag }`;
        } else {
          returnString += `${ tag }, `;
        }
      });
      return returnString;
    },
    getGeoIp (val) {
      this.axios.get(`/CentralDeviceManagement/GetDeviceCommands?deviceUuid=${ val }`)
        .then((response) => {
          if (response.data && response.data.length > 0 && response.data.find(x => x.name == "showInternetIP") != null) {
            let commandRequest = {
              'Command': 'showInternetIP',
              'Args': ''
            };
            this.geoMapLoading = true;
            this.showGeoMap();
            this.axios.post(`/CentralDeviceManagement/ExecuteCommand?deviceUuid=${ val }`, commandRequest)
            .then(async (response) => {
              if (response.data && response.data.output) {
                this.deviceLocation = await (await fetch(`https://reallyfreegeoip.org/json/${ response.data.output }`)).json();
                if(this.deviceLocation && this.deviceLocation.latitude && this.deviceLocation.longitude) {
                  this.geoMapLoading = false;
                   this.$nextTick(() => {
                    this.initMap();
                    if (this.locationMarker != null) {
                      this.map.removeLayer(this.locationMarker)          
                    }

                    this.locationMarker = L.marker([this.deviceLocation.latitude, this.deviceLocation.longitude]).addTo(this.map);
                      let latLngs = [ this.locationMarker.getLatLng() ];
                      let markerBounds = L.latLngBounds(latLngs);
                      this.map.fitBounds(markerBounds);
                  });
                }
                else {
                  this.$snotify.error('Location not found.');
                  this.closeGeoMap();
                }
              }
              else {
                this.$snotify.error('Internet Ip command not found.');    
                this.closeGeoMap();
                this.geoMapLoading = false;
              }
            })
            .catch(() => {
              this.closeGeoMap();
              this.geoMapLoading = false;
            });   
          }
          else {
            this.$snotify.error('showInternetIP command not found.');
          }
      });
    },
    setDeviceInstallationId () {
      let temporaryDevices = this.devicesToSet;
      let axiosRequests = [];
      temporaryDevices.forEach((device) => {
        axiosRequests.push(
          this.axios.put(`/CentralDeviceManagement/SetDeviceInstallationId?deviceUuid=${ device.uuid }&installationId=${ this.installationToUse.id }`)
        );
      });
      this.loading = true;
      this.axios.all(axiosRequests)
        .then(this.axios.spread(() => {
          this.$snotify.success(this.$t('unconfiguredDevicesDisplayComponent.devicesSetSuccessfully'));
        }))
        .finally(() => {
          this.searchDevices();
        });
    },
    searchDevices () {
      this.loading = true;
      this.cancelSearch();
      this.cancelSource = this.axios.CancelToken.source();
      this.axios.all([
        this.axios.get('/CentralDeviceManagement/GetAllUnconfiguredDevices', { cancelToken: this.cancelSource.token }),
        this.axios.get('/Installation/GetAll')
      ])
        .then(
          this.axios.spread((deviceResponse, installationResponse) => {
            if (deviceResponse && deviceResponse.data) {
              this.devices = deviceResponse.data;
            }
            if (installationResponse && installationResponse.data) {
              this.installations = installationResponse.data;
            }
        }))
        .catch(() => {
          if (this.axios.isCancel()) {
            return;
          }
        })
        .finally(() => {
          this.loading = false;
        });
    },
    cancelSearch () {
      if (this.cancelSource) {
        this.cancelSource.cancel('Start new search, stop active search');
      }
    },
    //map
    initMap () {
      let wikiMap = L.tileLayer(this.wikiUrl, {attribution: this.contributionUrl});
      let landMap = L.tileLayer(this.mapUrl, {attribution: this.mapAttrib});
      this.baseLayers = {};
      this.baseLayers['Satellite'] = landMap;
      this.baseLayers['Map'] = wikiMap;

      this.map = L.map('map', {
        layers: [
          wikiMap
        ],
        zoomDelta: 0.5, 
        zoomSnap: 0
      });
      this.map.addLayer(wikiMap);
    },
    changeBaseLayer () {
      if (this.baseLayerBool == true) {
        this.map.removeLayer(this.baseLayers['Satellite']);
        this.map.addLayer(this.baseLayers['Map']);
      } else {
        this.map.removeLayer(this.baseLayers['Map']);
        this.map.addLayer(this.baseLayers['Satellite']);
      }
    },
  }
}
</script>

<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>

<style scoped>
.form-check-input {
  margin-top: -.3rem;
}

.defaultTable > tbody > tr.clicked > td {
    background: #00000038;
}
.defaultTable > tbody > tr.clicked:hover > td {
    background: #00000038;
}
</style>
